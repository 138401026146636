<template>
  <LineChartGenerator
    :chart-options="chartOptions"
    :chart-data="chartData"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="width"
    :height="height"
  />
</template>

<script>
import { Line as LineChartGenerator } from "vue-chartjs/legacy";
import moment from "moment";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
);

export default {
  name: "LineChart",
  components: {
    LineChartGenerator,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
    chartId: {
      type: String,
      default: "line-chart",
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },
    width: {
      type: Number,
      default: 300,
    },
    height: {
      type: Number,
      default: 300,
    },
    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      chartData: {
        labels: [],
        datasets: [],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
      },
    };
  },
  mounted() {
    this.getDays();
    this.setData();
  },
  methods: {
    getDays() {
      const daysInMonth = moment().daysInMonth();

      for (let i = 1; i <= daysInMonth; i++) {
        this.chartData.labels.push(`Día ${i}`);
      }
    },
    setData() {
      for (const courier of this.data) {
        const dataset = {
          label: courier.name,
          backgroundColor: this.getRandomColor(),
          data: [],
        };
        const daysInMonth = moment().daysInMonth();

        const dayTuple = courier.days.reduce((acc, item) => {
          acc[item.day] = item.quantity;
          return acc;
        }, {});
        for (let i = 1; i <= daysInMonth; i++) {
          const cont = dayTuple[i] ? dayTuple[i] : 0;
          dataset.data.push(cont);
        }
        this.chartData.datasets.push(dataset);
      }
    },
    getRandomColor() {
      const h = Math.floor(Math.random() * 360);
      const s = Math.floor(Math.random() * 40 + 40) + "%"; // Saturación entre 40% y 80%
      const l = Math.floor(Math.random() * 30 + 35) + "%"; // Luminosidad entre 35% y 65%

      return `hsl(${h}, ${s}, ${l})`;
    },
  },
};
</script>
