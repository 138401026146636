<template>
  <div class="card">
    <div class="card-body">
      <h4 class="card-title">Notificaciones</h4>
      <div class="notificaciones-card">
        <div v-for="(notification, index) in data" :key="index">
          <NotificationItem :notification="notification" />
          <el-divider
            v-if="index != data.length - 1"
            class="mb-1 mt-1"
          ></el-divider>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NotificationItem from "./NotificationItem.vue";
export default {
  components: {
    NotificationItem,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    fileStatus() {
      return this.$store.state.dashboard.file.status;
    },
  },
  methods: {
    showFile(blob) {
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob.file);
      link.download = blob.filename;
      link.click();
      URL.revokeObjectURL(link.href);
    },
  },
  watch: {
    fileStatus(val) {
      if (val.downloaded) {
        this.showFile(this.$store.state.dashboard.file.data);
        return;
      }
      if (val.error) {
        this.$notify({
          message: "Ha ocurrido un error la descargar el fichero!",
          type: "danger",
        });
        return;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.notificaciones-card {
  min-height: 300px;
  max-height: 300px;
  padding-top: 20px;
  overflow: auto;
}
</style>