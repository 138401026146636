<template>
  <auth-layout pageClass="login-page">
    <div class="row d-flex justify-content-center align-items-center">
      <div class="col-lg-4 col-md-6 col-sm-8">
        <ValidationObserver v-slot="{ handleSubmit }">
          <!--You can specify transitions on initial render. The `card-hidden` class will be present initially and then it will be removed-->
          <form @submit.prevent="handleSubmit(submit)">
            <fade-render-transition>
              <card>
                <div slot="header" class="header-login">
                  <div>
                    <h3 class="card-title text-center">¿Olvidaste tu contraseña?</h3>
                  </div>
                  <div class="d-flex justify-content-center pb-2">
                    <img class="text-center" src="/static/img/logo-oxperta-login.png" />
                  </div>
                  <div class="text-center">
                    <p>{{ texto }}</p>
                  </div>
                </div>
                <div>
                  <ValidationProvider v-if="!finished" name="email" rules="required|email" v-slot="{ passed, failed }">
                    <fg-input :error="failed ? 'El usuario es requerido' : null" :hasSuccess="passed" label="Email"
                      name="email" v-model="email">
                    </fg-input>
                  </ValidationProvider>
                </div>
                <div class="text-center pt-4">
                  <button v-if="!finished" type="submit" class="btn btn-fill btn-info btn-round btn-wd"
                    :disabled="!isActive">
                    Enviar enlace
                  </button>
                </div>
              </card>
            </fade-render-transition>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </auth-layout>
</template>
<script>
import {
  FadeRenderTransition,
} from "src/components/index";
import AuthLayout from "./AuthLayout.vue";
import { extend } from "vee-validate";
import { required, email } from "vee-validate/dist/rules";

extend("email", email);
extend("required", required);

export default {
  components: {
    FadeRenderTransition,
    AuthLayout,
  },
  data() {
    return {
      texto: "Indique su correo electrónico, si existe como cuenta, le enviaremos un enlace para restablecerla.",
      email: "",
      isActive: true,
      finished: false
    };
  },
  mounted() {
  },
  computed: {
    statusRestore() {
      return this.$store.state.user.enlace.status;
    }
  },
  methods: {
    submit() {
      this.$store.dispatch("user/getEnlaceRecuperacion", { origen: "PF", user: this.email });
    },
    notificacion(titl, msg, typ) {
      this.$message({
        title: titl,
        message: msg,
        type: typ,
      });
    },
    toggleNavbar() {
      document.body.classList.toggle("nav-open");
    },
    closeMenu() {
      document.body.classList.remove("nav-open");
      document.body.classList.remove("off-canvas-sidebar");
    },
  },
  watch: {
    statusRestore(val) {
      if (val.getted) {
        this.isActive = true;
        this.texto = "Solicitud enviada correctamente, revise su correo electrónico para acceder al enlace de cambio de contraseña."
        this.finished = true;
      } else if (val.error) {
        this.isActive = true;
        this.notificacion("Error!", val.error.error, "error");        
      }
    },
  },
  beforeUnmount() {
    this.closeMenu();
  },
};
</script>
<style scoped lang="scss">
.navbar-nav .nav-item p {
  line-height: inherit;
  margin-left: 5px;
}

.header-login {
  display: grid;

  >div {
    width: 100%;
  }

  justify-content: center;
}
</style>
