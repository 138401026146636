var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('auth-layout',{attrs:{"pageClass":"login-page"}},[_c('div',{staticClass:"row d-flex justify-content-center align-items-center"},[_c('div',{staticClass:"col-lg-4 col-md-6 col-sm-8"},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('fade-render-transition',[_c('card',[_c('div',{staticClass:"header-login",attrs:{"slot":"header"},slot:"header"},[_c('div',[_c('h3',{staticClass:"card-title text-center"},[_vm._v("Iniciar Sesión")])]),_c('div',[_c('img',{attrs:{"src":"/static/img/logo-oxperta-login.png"}})])]),_c('div',[_c('ValidationProvider',{attrs:{"name":"email","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"error":failed ? 'El usuario es requerido' : null,"hasSuccess":passed,"label":"Usuario","name":"email"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"password","rules":"required|min:5"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{staticClass:"pt-2",attrs:{"type":"password","error":failed ? 'Contraseña requerida' : null,"hasSuccess":passed,"name":"password","label":"Contraseña"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}],null,true)})],1),_c('div',{staticClass:"text-center pt-4"},[_c('button',{staticClass:"btn btn-fill btn-info btn-round btn-wd",attrs:{"type":"submit","disabled":!_vm.isActive}},[_vm._v(" Iniciar Sesión ")]),_c('br'),_c('div',{staticClass:"forgot"},[_c('router-link',{staticClass:"card-category",attrs:{"to":"/recover_password"}},[_vm._v(" ¿Olvidaste tu contraseña? ")])],1)])])],1)],1)]}}])})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }