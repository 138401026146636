<template>
  <!-- action global -->
  <div class="col-12 m-0 p-0 toolbar">
    <ul class="nav nav-pills">
      <li
        class="nav-item"
        v-for="(item, key) in acciones"
        :key="key"
        v-loading="loadings.includes(item.evento)"
      >
        <div v-if="!item.disabled">
          <a
            v-if="item.items"
            class="nav-link dropdown-toggle"
            :class="{ active: item.activo }"
            data-bs-toggle="dropdown"
            href="#"
            role="button"
            aria-expanded="false"
          >
            <i v-if="item.icon != ''" class="fa" :class="item.icon"></i>
            {{ item.label }}
          </a>
          <ul v-if="item.items" class="dropdown-menu">
            <li v-for="(subItem, key) in item.items" :key="key">
              <a
                class="dropdown-item gray"
                href="#"
                @click="launchEvents(subItem)"
              >
                <i
                  v-if="item.icon != ''"
                  class="gray me-2 fa"
                  :class="subItem.icon"
                ></i>
                {{ subItem.label }}
              </a>
            </li>
          </ul>

          <a
            v-else
            class="nav-link text-sm"
            :class="{ active: item.activo }"
            @click="launchEvents(item)"
          >
            <i v-if="item.icon != ''" class="fa" :class="item.icon"></i>
            {{ item.label }}
          </a>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  components: {},
  name: "Actions",
  props: {
    acciones: {
      required: true,
      default: () => [],
    },
    loadings: {
      required: false,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  mounted() {},
  computed: {},
  methods: {
    launchEvents(accion) {
      this.$emit("triggerEvent", accion.evento);
    },
  },
  watch: {},
};
</script>
<style lang="scss">
.input-class .el-form-item__label {
  float: none;
  display: inline-block;
  text-align: left;
  padding: 0 0 0px !important;
  margin: 0px 0px -7px 0px !important;
}

.box-card-actions .el-card__body {
  padding: 0px 20px 0px 20px !important;
}

.button-tittle {
  font-size: 0.7rem;
}
.action-tittle {
  font-size: 0.9rem;
  font-weight: bold;
  -ms-transform: translateY(28%);
  transform: translateY(28%);
}

.gray {
  color: #888888;
}
</style>
